.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  font-family: "Times New Roman", Times, serif;
}

.content {
  text-align: left;
  width: 90%;
  max-width: 400px;
  position: relative;
  transform: translate(-50%, -50%);
}

.name p{
  font-size: 2.5rem;
  font-family: "Papyrus", Times, serif;
}



p {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color:beige;
}


p {
  font-size: 1rem;
  line-height: 1.5;
  color: beige;
}

.Portfolios {
  margin-top: 20px;
  font-size: 0.9rem;
  color: beige;
}

.Portfolios a {
  text-decoration: underline;
  color: beige;
}


@media (max-width: 768px) {
  .content {
    width: 90%; /* 改为百分比宽度 */
    transform: translate(0, 0); /* 移除translate以居中显示 */
    text-align: center; /* 居中对齐文本 */
  }

  .name p {
    font-size: 2rem; /* 进一步缩小字体 */
  }

  p {
    font-size: 1rem; /* 字体更小，适应屏幕 */
  }

  .avatar img {
    width: 100px; /* 缩小头像 */
  }
}

@media (max-width: 480px) {
  .content {
    width: 100%; /* 在超小屏幕上使用全宽 */
  }

  .name p {
    font-size: 1.8rem; /* 调整字体以适应超小屏幕 */
  }

  p {
    font-size: 0.9rem; /* 缩小文字 */
  }

  .avatar img {
    width: 80px; /* 进一步缩小头像尺寸 */
  }
}

/* 针对MacBook等大屏幕设备的优化 */
@media (min-width: 1050px) {
  .content {
    width: 50%;  /* 在较大屏幕上使用50%的宽度 */
    transform: translate(-50%, -50%); /* 确保居中 */
  }

  .name p {
    font-size: 2.5rem; /* 适当调整字体大小 */
  }

  p {
    font-size: 1.2rem; /* 大屏上保持原字体 */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .content {
    width: 80%; /* 在iPad等设备上适当调整宽度 */
  }

  .name p {
    font-size: 2.2rem; /* 适当缩小字体 */
  }

  p {
    font-size: 1.1rem; /* 适当缩小文字 */
  }

  .avatar img {
    width: 120px; /* 调整头像大小 */
  }
}

@media (orientation: portrait) and (max-width: 850px) {
  .content {
    width: 95%; /* 在竖屏下使用更大的宽度 */
    transform: none; /* 移除transform以确保居中 */
    text-align: center; /* 居中对齐文本 */
  }

  .name p {
    font-size: 2rem; /* 适当缩小字体 */
  }

  p {
    font-size: 1rem; /* 适当缩小文字 */
  }

  .avatar img {
    width: 100px; /* 缩小头像 */
  }
}